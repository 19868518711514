import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";

import phonesmallicon from "../../../assets/images/successfulcards/phone-small-icon.png";

import mapsmallicon from "../../../assets/images/successfulcards/map-small-icon.png";

import envelope from "../../../assets/images/successfulcards/envelope-fill.svg";

import deletebin from "../../../assets/images/myregisteration/trash-fill.svg";

import Breadcrumbsbanner from "../breadcumbbanner/breadcumbsbanner";
import KanhaRunService from "../../services/kanha.service";
import { navigate } from "gatsby";
import { Button, Modal } from "react-bootstrap";
import backn from "../../../assets/images/backIconSummary.svg";
// import logOutimg from "../../../assets/images/logOut.png";
import ConfirmInvoice from "../confirmInvoice";
import { CreateImageFromInitials, GetRandomColor } from "../../utils/common";
import { lStorage } from "../../utils/storage";
import Loading from "../loading";
import moment from "moment";
import "../../../assets/styles/checkout/style.css";
// import checkout from "./checkout.json";
import { json, useSubmit } from "react-router-dom";

export default function SuccesfulCards() {
  const [raceList, setRaceList] = useState([]);
  const pList = lStorage.get("list");
  const kanhaApi = new KanhaRunService();
  const [formData, setFormData] = useState(pList);
  const [paymentPage, setPaymentPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonEnable, setButtonEnable] = useState("");
  const [discountPrice, setdiscountPrice] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [discountError, setDiscountError] = useState("");
  const [discountSuccess, setDiscountSuccess] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [totalDiscountAmount, setTotalDiscountAmount] = useState("");
  const [totalPayableAmount, setTotalPayableAmount] = useState("");
  const [totalItems, setTotalItems] = useState("");

  const [disName, setDisName] = useState("");

  const [raceArray, setRaceArray] = useState([]);
  const [accomodationArray, setAccomodationArray] = useState([]);
  const [bidDeliveryArray, setBidDeliveryArray] = useState([]);
  const [giftTreeArray, setGiftTreeArray] = useState([]);
  const [giftRunArray, setGiftRunArray] = useState([]);
  const [discountArray, setDiscountArray] = useState([]);
  const [globalRun, setGlobalRun] = useState(0);
  const [totalTreePrice, setTotalTreePrice] = useState(0);
  const [totalRunPrice, setTotalRunPrice] = useState(0);
  const [raceType, setRaceType] = useState();
  const [invoiceLocation, setInvoiceLocation] = useState();

  const deleteData = async (data) => {
    let removeData = formData && formData.filter((e) => e !== data);
    lStorage.set("list", removeData);
    const parseList = removeData;
    await setFormData(parseList);
    window.location.reload();
  };

  const getraceList = () => {
    kanhaApi
      .getracecategory()
      .then((response) => {
        if (
          response.data.isError === false ||
          response.data.statusCode === "200"
        ) {
          if (response.data.isError === false) {
            setRaceList(response.data.data);
            const pList = lStorage.get("list");
            if (pList && pList.length === 0) {
              navigate("/myregisterationlist");
            }
          } else {
            // console.log("error");
            const pList = lStorage.get("list");
            if (pList && pList.length === 0) {
              navigate("/myregisterationlist");
            }
          }
          //  navigate("/basicformpage");
        }
      })
      .catch(function (error) {
        // console.log(error);
        logOut();
        const pList = lStorage.get("list");
        if (
          (pList && pList === null) ||
          (pList && pList === undefined) ||
          (pList && pList.length === 0)
        ) {
          navigate("/myregisterationlist");
        }
      });
  };

  useEffect(() => {
    const selectedLocation = lStorage.get("selectedLocation");
    setRaceType(selectedLocation.runType);
    setInvoiceLocation(selectedLocation);

    const isGlobalRun = lStorage.get("globalRun");
    if (isGlobalRun === 1) {
      setGlobalRun(1);
    }

    if (localStorage.getItem("list") !== undefined) {
      const checkout = JSON.parse(localStorage.getItem("list"));
      setTotalAmount(
        checkout.reduce(function (prev, current) {
          return prev + current.race_price;
        }, 0)
      );
      setTotalDiscountAmount(
        checkout.reduce(function (prev, current) {
          return prev + current.discount_price;
        }, 0)
      );
      setTotalPayableAmount(
        checkout.reduce(function (prev, current) {
          return prev + current.final_amount;
        }, 0)
      );
      setTotalItems(checkout.length);
    }

    if (
      localStorage.getItem("appliedCouponCode") !== null &&
      localStorage.getItem("appliedCouponCode") !== ""
    ) {
      const payload = {
        coupon_code: localStorage.getItem("appliedCouponCode"),
      };
      kanhaApi.getDiscountedPriceValue(payload).then((res) => {
        if (res.data.isError) {
          defaultDiscountPrice();
        } else {
          setDiscountError("");
          setdiscountPrice(res.data.data[0].discount_price);
          setDisName(res?.data?.data?.[0]?.discount_name);
          updateCartPrice(res?.data?.data?.[0]);
        }
      });
    } else {
      defaultDiscountPrice();
    }
  }, []);

  const updateCartPrice = (discountdata) => {
    if (discountdata !== undefined) {
      const storedCheckoutData = localStorage.getItem("list");
      const checkout = JSON.parse(storedCheckoutData);
      const updatedCheckout = checkout.map((item) => {
        if (item.race_category_id <= 4) {
          const single_amt = item.race_price - discountdata?.discount_price;
          return {
            ...item,
            discount_price: discountdata.discount_price,
            final_amount: single_amt,
            discount_code: discountdata.discount_code,
            discount_name: discountdata.discount_name,
          };
        } else {
          return {
            ...item,
            discount_price: 0,
            final_amount: item.race_price,
            discount_code: "",
            discount_name: "",
          };
        }
      });

      // console.log(updatedCheckout);
      localStorage.setItem("list", JSON.stringify(updatedCheckout));
      setTotalAmount(
        updatedCheckout.reduce(function (prev, current) {
          return prev + current.race_price;
        }, 0)
      );
      setTotalDiscountAmount(
        updatedCheckout.reduce(function (prev, current) {
          return prev + current.discount_price;
        }, 0)
      );
      setTotalPayableAmount(
        updatedCheckout.reduce(function (prev, current) {
          return prev + current.final_amount;
        }, 0)
      );

      setFormData(updatedCheckout);
    }
    // Update checkout data in localStorage
  };

  const defaultDiscountPrice = () => {
    kanhaApi.getDiscountCode().then((res) => {
      const storedCheckoutData = localStorage.getItem("list");
      const checkoutData = JSON.parse(storedCheckoutData);
      setdiscountPrice(res.data.data[0]?.discount_price);
      setDisName(res?.data?.data?.[0]?.discount_name);
      updateCartPrice(res?.data?.data?.[0]);
      //setdiscountPrice("250")
    });
  };

  const logOut = () => {
    lStorage.remove("userInfo");
    lStorage.remove("verification_id");
    lStorage.remove("list");
    lStorage.remove("globalRun");
    lStorage.remove("appliedCouponCode");
    navigate("/");
  };

  const backToParticipant = () => {
    navigate("/myregisterationlist");
  };
  const backToRegister = () => {
    navigate("/basicformpage");
  };
  const confirmPay = () => {
    setPaymentPage(true);
  };
  const closePayment = () => {
    setPaymentPage(false);
  };
  const proceedToPay = async (data) => {
    if (data.first_name) {
      const payLoad = await {
        invoicedetails: [
          {
            invoice_name: data.first_name,
            invoice_emailid: data.email_address,
            invoice_address: data.address,
            country_id: invoiceLocation.country_id,
            state_id: invoiceLocation.state_id,
            city_id: invoiceLocation.city_id,
          },
        ],
        participants: pList,
      };
      console.log("payLoad", payLoad);
      setLoading(true);
      setPaymentPage(false);
      setLoading(true);
      await kanhaApi
        .addparticipant(payLoad)
        .then((response) => {
          if (
            response.data.isError === false ||
            response.data.statusCode === "200"
          ) {
            if (response.data.isError === false) {
              lStorage.remove("list");
              lStorage.remove("appliedCouponCode");
              window.open(response.data.message.paymentUrl, "_parent");
              setLoading(false);
            } else {
              setLoading(false);
              // console.log("error");
            }
          } else {
            if (response.data.isError === true) {
              setButtonEnable("error");
              setLoading(false);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          setLoading(false);
          logOut();
        });
    }
  };

  const initialValue = 0;
  const total =
    pList &&
    pList.reduce(
      (accumulator, current) => accumulator + current.race_price,
      initialValue
    );

  useEffect(() => {
    getraceList();
    const pList = lStorage.get("list");
    if (pList === undefined || (pList && pList.length === 0)) {
      navigate("/myregisterationlist");
    }
  }, [formData]);

  const getDiscountPrice = () => {
    if (discountCode == "") {
      setDiscountError("Enter Discount code");
    } else {
      const payload = {
        coupon_code: discountCode,
      };
      kanhaApi.getDiscountedPriceValue(payload).then((res) => {
        if (res.data.isError) {
            setDiscountError(res.data.message);
        } else {
          if(raceType==="Timed Run"){
          localStorage.setItem("appliedCouponCode", discountCode);
          setDiscountSuccess("Coupon Applied successfully");
          setdiscountPrice(res.data.data[0].discount_price);
          setDisName(res?.data?.data?.[0]?.discount_name);
          updateCartPrice(res?.data?.data?.[0]);
          }else{
            setDiscountError("Invalid Coupon Code");
          }
        }
      });
    }
  };



  const getCount = (arrayToCheck, fieldToCount) => {
    // console.log("CHeckout", fieldToCount, arrayToCheck);
    const countsByField = {};
    arrayToCheck.forEach((field) => {
      countsByField[field?.[fieldToCount]] =
        (countsByField[field?.[fieldToCount]] || 0) + 1;
    });

    const finalArray = Object.entries(countsByField)
      .map(([field, count]) => ({ field, count }))
      .sort((a, b) => b.count - a.count);

    if (fieldToCount === "race_category_name") {
      setRaceArray(finalArray);
    } else if (fieldToCount === "overnight_accommodation") {
      setAccomodationArray(finalArray);
    } else if (fieldToCount === "bib_collection_location") {
      setBidDeliveryArray(finalArray);
    } else if (fieldToCount === "gift_a_tree_count") {
      setGiftTreeArray(finalArray);
    } else if (fieldToCount === "gift_a_run") {
      setGiftRunArray(finalArray);
    } else if (fieldToCount === "discount_name") {
      setDiscountArray(finalArray);
    }
  };

  useEffect(() => {
    const selectedLocation = lStorage.get("selectedLocation");
    setRaceType(selectedLocation.runType);
    getCount(formData, "race_category_name");
    getCount(formData, "overnight_accommodation");
    getCount(formData, "gift_a_tree_count");
    getCount(formData, "gift_a_run");
    getCount(formData, "discount_name");
    getCount(formData, "bib_collection_location");
    var treePrice = 0;
    formData.forEach((record, index) => {
      if (
        (selectedLocation.runType === "Timed Run" ||
          selectedLocation.runType === "Global Run") &&
        record.gift_a_tree_count > 0
      ) {
        treePrice = treePrice + record.gift_a_tree_count * 500;
      }
      if (
        selectedLocation.runType === "Overseas" &&
        record.gift_a_tree_count > 0
      ) {
        treePrice = treePrice + record.gift_a_tree_count * 2000;
      }
    });
    var runPrice = 0;
    formData.forEach((record, index) => {
      if (
        (selectedLocation.runType === "Timed Run" ||
          selectedLocation.runType === "Global Run") &&
        record.gift_a_run > 0
      ) {
        runPrice = runPrice + record.gift_a_run * 600;
      }
      if (selectedLocation.runType === "Overseas" && record.gift_a_run > 0) {
        runPrice = runPrice + record.gift_a_run * 1000;
      }
    });
    setTotalTreePrice(treePrice);
    setTotalRunPrice(runPrice);
  }, [formData]);

  function addNum(total, num) {
    return total + num;
  }

  // function addNums(total, num) {
  //   return total + num;
  // }

  const giftTreeCount = giftTreeArray?.map((item) => {
    return Number(item.field * item.count);
  });

  const giftRunCount = giftRunArray?.map((item) => {
    return Number(item.field * item.count);
  });

  // const discountCount = discountArray?.map((item) => {
  //   return Number(item.field*item.count);
  // });

  const getRaceAmount = (raceField, raceCount) => {
    switch (raceField) {
      case "Global Run - 149":
        return 149 * raceCount;
      case "Global Run - 399":
        return 399 * raceCount;
      case "Global Run - 599":
        return 599 * raceCount;
      case "Global Run - 1000":
        return 1000 * raceCount;
      case "21 KM Timed":
        return 900 * raceCount;
      case "10 KM Timed":
        return 800 * raceCount;
      case "05 KM Timed":
        return 700 * raceCount;
      case "1.5 KM Non Timed":
        return 600 * raceCount;
      default:
        break;
    }
  };

  const getOverseasPriceCal = (price) => {
    const unitPrice = 12;
    const calculatedPrice = (price / 1000) * unitPrice;
    return `${calculatedPrice} USD`;
  };

  return (
    <>
      <Breadcrumbsbanner />
      <div className="succesfulcards-wrapper inner-pages p-4">
        <div className="container-fluid" style={{ marginBottom: "120px" }}>
          <div className="row">
            <div className="p-2">
              {/* <span onClick={backToParticipant} className="hand">
                <img className="img-fluid" src={backn} alt="back" />
              </span> */}
              <Button
                className="btn btn-primary"
                // style={{ margin: "0 12px" }}
                onClick={() => navigate("/myregisterationlist")}
              >
                {/* <span onClick={backToParticipant} className="hand"> */}
                <img className="img-fluid" src={backn} alt="back" />
                {/* </span>{" "} */}
                Back to My Registration
              </Button>
              <Button
                className="btn btn-dark btn-top-mobile10"
                onClick={backToRegister}
                disabled={pList?.length >= 5 ? true : false}
                style={{ margin: "0px 0px 0px 15px" }}
              >
                Add More Participant
              </Button>
              {/* <Button
                className="btn btn-primary btn-block"
                style={{ float: "right" }}
                onClick={logOut}
              >
                Logout{" "}
                <img
                  className="img-fluid"
                  src={logOutimg}
                  width="15px"
                  alt="Clock logo"
                />
              </Button> */}
            </div>
            <div className="col-md-9">
              <div className="succesfulcards-wrapper-box">
                <div className="pnrnumber-details">
                  <b>Registration Summary</b>&nbsp;
                  <span> ( Participants Count {pList && pList.length})</span>
                </div>

                {/* <div>
                  <span> Total Amount </span>
                  <span>
                    <b> {total} ₹ </b>
                  </span>
                </div> */}
              </div>
            </div>
          </div>

          {globalRun != 1 && (
            <div
              class="row"
              style={{
                paddingTop: "10px",
              }}
            >
              <div class="col-sm-6 col-md-12">
                <div class="row">
                  <div class="col-sm-6 col-md-6">
                    <p className="code">
                      Discount Code ( Only 1 Discount code is applicable )
                    </p>
                    <input
                      className="col-xs-3 form-control discount_code_input"
                      type="text"
                      onChange={(e) => {
                        setDiscountError("");
                        setDiscountSuccess("");
                        setDiscountCode(e.target.value);
                      }}
                    />
                    {discountError && (
                      <div
                        style={{
                          color: "#FF0000",
                        }}
                      >
                        <b> {discountError}</b>
                      </div>
                    )}
                    {discountSuccess && (
                      <div
                        style={{
                          color: "#00FF00",
                        }}
                      >
                        <b> {discountSuccess}</b>
                      </div>
                    )}
                  </div>
                  <div
                    class="col-sm-6 col-md-12"
                    style={{
                      paddingTop: "10px",
                    }}
                  >
                    <button
                      className="btn discount_code_submit_btn"
                      onClick={() => getDiscountPrice()}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row">
            {formData &&
              formData.map((data, index) => {
                return (
                  <div className="col-md-3 col-sm-6" key={index + 1}>
                    <div className="card succesful-cards-wrapper first-upper">
                      <Card>
                        <Card.Body>
                          <div
                            className="name-wrapper-item"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <span>
                                <img
                                  id="preview"
                                  width="35px"
                                  src={CreateImageFromInitials(
                                    500,
                                    data.first_name,
                                    GetRandomColor()
                                  )}
                                  alt=""
                                />
                              </span>
                            </div>
                            <div>
                              <div
                                className="deleteiocn hand"
                                onClick={() => {
                                  navigate(`/basicformpage`);
                                  lStorage.set("editSecretId", data.secretId);
                                }}
                                style={{
                                  marginRight: "55px",
                                }}
                                title="Edit"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="15"
                                  fill="currentColor"
                                  class="bi bi-pen"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                                </svg>
                              </div>{" "}
                              <div>
                                <h5
                                  className="deleteiocn hand"
                                  onClick={() => {
                                    deleteData(data);
                                  }}
                                  style={{
                                    paddingTop: "3px",
                                  }}
                                  title="Delete"
                                >
                                  <img
                                    className="img-fluid"
                                    src={deletebin}
                                    alt="Clock logo"
                                  />
                                </h5>
                              </div>
                            </div>
                          </div>
                          <ul style={{ paddingTop: "10px" }}>
                            <li>
                              <b>Name</b>
                            </li>
                            <li>
                              {data.first_name} {data.last_name}
                            </li>
                            <li>
                              <b>Gender</b>
                            </li>
                            <li>{data.gender}</li>
                            {data?.blood_group != "" && (
                              <>
                                {data?.race_km?.includes("virtual-run") ===
                                  false && (
                                  <>
                                    <li>
                                      <b>Blood group </b>
                                    </li>
                                    <li>{data.blood_group}</li>
                                  </>
                                )}
                              </>
                            )}
                            {data?.dob != "" && (
                              <li>
                                <b>DOB</b>
                              </li>
                            )}
                            {data?.dob != "" && (
                              <li>
                                {moment(data && data.dob).format("DD-MM-YYYY")}
                              </li>
                            )}
                            {data?.age != "" && data?.dob == "" && (
                              <li>
                                <b>Age</b>
                              </li>
                            )}
                            {data?.age != "" && data?.dob == "" && (
                              <li>{data.age}</li>
                            )}
                            {/* <li>
                              <b>BIB Name</b>
                            </li>
                            <li>{data.name_on_bib}</li> */}
                            {data?.t_shirt_size != "" && (
                              <li>
                                <b>T-shirt Size</b>
                              </li>
                            )}
                            {data?.t_shirt_size != "" && (
                              <li>{data.t_shirt_size}</li>
                            )}
                          </ul>

                          <div>
                            <ul className="contact-wrapper-box">
                              <li>
                                <span>
                                  <img
                                    className="img-fluid"
                                    src={phonesmallicon}
                                    alt="Clock logo"
                                  />
                                </span>
                                {data.contact_number}
                              </li>
                              <li style={{ textTransform: "none" }}>
                                <span>
                                  <img
                                    className="img-fluid"
                                    src={envelope}
                                    alt="Clock logo"
                                  />
                                </span>
                                {data.email_address}
                              </li>
                              {data?.address != "" && (
                              <li>
                                <span>
                                  <img
                                    className="img-fluid"
                                    src={mapsmallicon}
                                    alt="Clock logo"
                                  />
                                </span>
                                {data.address}
                              </li>
                               )}
                              {data?.overnight_accommodation != "" && (
                                <li>
                                  <b>Accommodation</b> :{" "}
                                  {data.overnight_accommodation === "Y"
                                    ? "Yes"
                                    : "No"}
                                </li>
                              )}
                              {data?.bib_collection_location != "" && (
                                <li>
                                  <b>BIB collection location</b> :{" "}
                                  {data.bib_collection_location}
                                </li>
                              )}
                              <li>
                                <b>Race Category</b> : {data.race_category_name}
                              </li>
                              {Number(data.gift_a_tree_count) > 0 && (
                                <li>
                                  <b>Gift A Tree Count</b> :{" "}
                                  {data.gift_a_tree_count}
                                </li>
                              )}
                              {Number(data.gift_a_run) > 0 && (
                                <li>
                                  <b>Gift A Run Count</b> : {data.gift_a_run}
                                </li>
                              )}
                            </ul>
                          </div>
                          <div className="amount-item-wrapper">
                            {data.discount_price > 0 && (
                              <div>
                                <h4>
                                  <span className="strike">
                                    ₹ {data?.race_price}

                                  </span>{" "}
                                  ₹ {data?.final_amount}
                                </h4>
                                <b>
                                  {disName} (- ₹ {data.discount_price})
                                </b>
                              </div>
                            )}
                            {data.discount_price <= 0 && (
                              <div>
                                <h4>₹ {data.final_amount}
                                {raceType && raceType === "Overseas" && (
                                  <>{" "}
                                   ({getOverseasPriceCal(data?.race_price)})
                                  </>
                                )
                                }
                                </h4>
                              </div>
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                );
              })}
          </div>

          <div>
            <div
              class="row"
              style={{
                paddingTop: "10px",
              }}
            >
              <div class="col-sm-6 col-md-7"></div>
              <div class="col-sm-6 col-md-5">
                <table class="table table-bordered">
                  {raceArray &&
                    raceArray?.map((item) => {
                      return (
                        <tr>
                          <td style={{ paddingTop: "20px" }}>
                            {item?.field} &#215; {item?.count}
                          </td>
                          <td style={{ paddingTop: "20px" }}>
                            <b>₹ {getRaceAmount(item?.field, item?.count)}</b>
                          </td>
                        </tr>
                      );
                    })}
                  {accomodationArray &&
                    accomodationArray?.map((item) => {
                      return (
                        <>
                          {item?.field === "Y" && (
                            <tr>
                              <td style={{ paddingTop: "20px" }}>
                                Accomodation &#215; {item?.count}
                              </td>
                              <td style={{ paddingTop: "20px" }}>
                                <b>₹ {item?.count * 500}</b>
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })}

                  {bidDeliveryArray &&
                    bidDeliveryArray?.map((item) => {
                      return (
                        <>
                          {item?.field === "Your Home Address" && (
                            <tr>
                              <td style={{ paddingTop: "20px" }}>
                              Home Delivery Charges &#215; {item?.count}
                              </td>
                              <td style={{ paddingTop: "20px" }}>
                                <b>₹ {item?.count * 119}</b>
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })}

                  {giftTreeCount.length > 0 &&
                    giftTreeCount.reduce(addNum) > 0 && (
                      <tr>
                        <td style={{ paddingTop: "20px" }}>
                          Gifted Tree &#215; {giftTreeCount.reduce(addNum)}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          <b>₹ {totalTreePrice} </b>
                        </td>
                      </tr>
                    )}

                  {giftRunCount.length > 0 &&
                    giftRunCount.reduce(addNum) > 0 && (
                      <tr>
                        <td style={{ paddingTop: "20px" }}>
                          Gifted Run &#215; {giftRunCount.reduce(addNum)}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          <b>₹ {totalRunPrice}</b>
                        </td>
                      </tr>
                    )}
                  <tr>
                    <td style={{ paddingTop: "20px" }}>
                      <b>Total Race Amount</b>
                    </td>
                    <td style={{ paddingTop: "20px" }}>
                      <b>₹ {totalAmount}</b>
                    </td>
                  </tr>

                  {discountArray.length > 0 &&
                    discountArray?.map((item) => {
                      return (
                        <>
                          {item?.field !== "" && (
                            <tr>
                              <td style={{ paddingTop: "20px" }}>
                                {disName} &#215; {item?.count}
                              </td>
                              <td style={{ paddingTop: "20px" }}>
                                <b>- ₹ {totalDiscountAmount}</b>
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })}

                  {/* <tr>
                    <td style={{ paddingTop: "20px"}}>({disName})</td>
                    <td style={{ paddingTop: "20px"}}><b>₹ {totalDiscountAmount}</b></td>
                  </tr>
                */}
                  <tr>
                    <td style={{ paddingTop: "20px" }}>
                      <b>Total Payable</b>
                    </td>
                    <td style={{ paddingTop: "20px" }}>
                      <b>₹ {totalPayableAmount}
                        {raceType && raceType === "Overseas" && (
                            <>{" "}
                              ({getOverseasPriceCal(totalPayableAmount)})
                            </>
                          )
                        }</b>
                    </td>
                  </tr>
                </table>

                {/* <table class="table table-bordered">
                  { raceArray && raceArray?.map(item =>{
                    return (
                      <tr  >
                    <td style={{ paddingTop: "20px"}}>{item?.field} &#215; {item?.count}</td>
                    <td style={{ paddingTop: "20px"}}><b>₹ {getRaceAmount(item?.field, item?.count)}</b></td>
                  </tr>
                    )
                  })}
                    { accomodationArray &&  accomodationArray?.map(item =>{
                    return (
                      <>
                      {item?.field === 'Y' && <tr  >
                    <td style={{ paddingTop: "20px"}}>Accomodation &#215; {item?.count}</td>
                    <td style={{ paddingTop: "20px"}}><b>₹ {item?.count * 500}</b></td>
                  </tr>}
                  </>
                    )
                  })}
                 {giftTreeCount.length > 0 &&
                  <tr>
                    <td style={{ paddingTop: "20px"}}>Gifted Tree &#215; {giftTreeCount.reduce(addNum)}</td>
                    <td style={{ paddingTop: "20px"}}><b>₹ {giftTreeCount.reduce(addNum) * 500}</b></td>
                  </tr>}
              </table> */}
                <div style={{ textAlign: "right" }}>
                  <Button
                    className="btn btn-orange proceed-button"
                    id="success-orange"
                    disabled={pList && pList.length === 0 ? true : false}
                    onClick={confirmPay}
                    style={{ margin: "10px 0px 0px 10px" }}
                  >
                    Proceed to Pay
                  </Button>
                </div>
              </div>{" "}
            </div>
          </div>
          {/*
<br></br>

          <div class="container">
            <div className="table_container">
              <div className="discount_tab">
                <p className="code">Discount Code : </p>
                <input
                  className="col-xs-3 form-control"
                  type="text"
                  onChange={(e) => {
                    setDiscountCode(e.target.value);
                  }}
                />
                <button
                  className="btn btn-outline-success"
                  onClick={() => getDiscountPrice()}
                >
                  Apply
                </button>
                {discountError}
              </div>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Participant Name</th>
                    <th>Race Name</th>
                    <th>Amount</th>
                    <th>Discount Price ( {disName} )</th>
                    <th>Final Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {JSON.parse(localStorage.getItem("list"))?.map((item) => {
                    return (
                      <tr>
                        <td>{item.first_name}</td>
                        <td>{item.race_category_name}</td>
                        <td>{item.race_price}</td>
                        <td>{item.discount_price}</td>
                        <td>{item.final_amount}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Total Amount</td>
                    <td>{totalAmount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <Button
            className="btn btn-orange"
            id="success-orange"
            disabled={pList && pList.length === 0 ? true : false}
            onClick={confirmPay}
            style={{ margin: "10px 0px 0px 780px", width: "20%" }}
          >
            Proceed to Pay
          </Button>
                */}
          {/* <div className="row">
            {formData &&
              formData.map((data, index) => {
                return (
                  <>
                    <div className="col-md-3 col-sm-6" key={index + 1}>
                      <div className="card succesful-cards-wrapper first-upper">
                      </div>
                    </div>
                   
                  </>
                );
              })}
          </div> */}
          {/* <div className="col-md-3 col-sm-6" style={{display:"inline"}}>
            <Button className="btn btn-dark" onClick={backToRegister}>
              Add More Participant
            </Button>
            &nbsp;&nbsp;
            <Button
              className="btn btn-orange"
              id="success-orange"
              disabled={pList && pList.length === 0 ? true : false}
              onClick={confirmPay}
            >
              Proceed to Pay
            </Button>
          </div> */}
        </div>
      </div>
      {paymentPage && paymentPage === true && (
        <ConfirmInvoice
          show={paymentPage}
          data={pList && pList[0]}
          apiResponse={buttonEnable}
          closePayment={closePayment}
          onSubmit={proceedToPay}
        />
      )}
      {loading === true && (
        <Modal id="loading" show={loading}>
          <Loading />
        </Modal>
      )}
    </>
  );
}
